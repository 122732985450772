import cn from 'clsx';
import * as React from 'react';

export const Button = React.memo<{
  onClick: React.DOMAttributes<HTMLButtonElement>['onClick'];
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
}>(({ className, disabled, children, onClick }) => {
  return (
    <button
      className={cn(
        className,
        'rounded-md text-center p-4 text-white text-xl outline-none focus:outline-none w-72 bg-indigo-500 font-semibold focus:ring',
        {
          'bg-opacity-75': disabled,
          'cursor-pointer': !disabled,
          'cursor-not-allowed': disabled,
        },
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
});
