import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import cn from 'clsx';
import * as React from 'react';

import { countries } from '../lib/countries';

export const CountrySelect = React.memo<{ value: typeof countries[0]; onChange(country: typeof countries[0]): void }>(
  ({ value, onChange }) => {
    return (
      <Listbox value={value} onChange={onChange}>
        <div className="absolute inset-y-0 left-0 flex items-center">
          <Listbox.Button className="relative h-full py-2 pl-3 text-left rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500">
            <span className="flex items-center truncate">
              <span className="text-xl">{value.flag}</span>
              <span className="block mx-1">+{value.countryCallingCode}</span>
              <ChevronDownIcon className="w-3 h-3 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg w-60 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {countries.map(country => (
              <Listbox.Option
                key={country.countryCode}
                className={({ active, selected }) =>
                  cn('cursor-default select-none relative py-2 pl-4 pr-4', {
                    'text-indigo-900 bg-indigo-100': active,
                    'text-indigo-900 bg-indigo-200': selected,
                  })
                }
                value={country}
              >
                <span className={cn('truncate flex items-center')}>
                  <span className="mr-1 text-xl">{country.flag}</span>
                  <span className="block truncate">{country.countryNameEn}</span>
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    );
  },
);
