/// <reference path="../types/sequences.d.ts" />

import { EmojiSequence } from '@prisma/client';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { fetchWithToken } from '../lib/fetchWithToken';

type EmojiSequencesResponse = Array<Pick<EmojiSequence, 'id' | 'tel' | 'attempts' | 'status' | 'updatedAt'>>;

export function useSequences(accountId: string, token: string) {
  const isServer = typeof window === 'undefined';

  const { data, isError, isLoading } = useQuery<EmojiSequencesResponse>(
    [`/api/accounts/${accountId}/sequences`, token],
    fetchWithToken,
    {
      enabled: Boolean(!isServer && token && accountId),
    },
  );

  return {
    emojiSequences: data || [],
    isError,
    isLoading,
  };
}

export function useGetSequence({ sequence_id }: ServiceSequences.GetSequenceParams) {
  const isServer = typeof window === 'undefined';

  return useQuery<ServiceSequences.GetSequenceResponse>(
    ['sequences', sequence_id],
    async () => {
      const res = await fetch(`/api/sequences/${sequence_id}`);
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.message);
      }
      return data;
    },
    {
      enabled: Boolean(!isServer && sequence_id),
    },
  );
}

const createSequence = async ({
  tel,
}: ServiceSequences.CreateSequenceParams): Promise<ServiceSequences.CreateSequenceResponse> => {
  const res = await fetch(`/api/sequences`, {
    method: 'POST',
    body: JSON.stringify({
      tel,
    }),
    headers: {
      'content-type': 'application/json',
    },
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message);
  }

  return data;
};

export function useCreateSequence() {
  const queryClient = useQueryClient();

  return useMutation<
    ServiceSequences.CreateSequenceResponse,
    { message: string },
    ServiceSequences.CreateSequenceParams
  >(createSequence, {
    onSuccess: data => {
      queryClient.setQueryData(['sequences', data.sequence_id], data);
    },
  });
}

const verifySequence = async ({
  sequence_id,
  emojis,
}: ServiceSequences.VerifySequenceParams): Promise<ServiceSequences.VerifySequenceResponse> => {
  const res = await fetch(`/api/sequences/${sequence_id}/verify`, {
    method: 'PUT',
    body: JSON.stringify({ emojis }),
    headers: {
      'content-type': 'application/json',
    },
  });
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message);
  }

  return data;
};

export function useVerifySequence() {
  const queryClient = useQueryClient();

  return useMutation<
    ServiceSequences.VerifySequenceResponse,
    ServiceSequences.Error,
    ServiceSequences.VerifySequenceParams
  >(verifySequence, {
    onError: (_err, { sequence_id }) => {
      const emojiSequence = queryClient.getQueryData<ServiceSequences.GetSequenceResponse>(['sequences', sequence_id]);
      queryClient.setQueryData(['sequences', sequence_id], {
        ...emojiSequence,
        attempts: emojiSequence.attempts + 1,
        attempts_remaining: emojiSequence.attempts_remaining - 1,
      });
    },
  });
}
