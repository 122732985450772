import cn from 'clsx';
import examples from 'libphonenumber-js/examples.mobile.json';
import { CountryCode, getExampleNumber, isSupportedCountry, isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { useRouter } from 'next/router';
import * as React from 'react';

import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { CountrySelect } from '../components/CountrySelect';
import { useCreateSequence } from '../hooks/sequences';
import { countries } from '../lib/countries';

export default function IndexPage() {
  const router = useRouter();
  const createSequence = useCreateSequence();
  const [selectedCountry, setSeletedCountry] = React.useState(countries[0]);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const sanitizedNumber = phoneNumber.replace(/[^0-9]/g, '');
  const selectedCountryCode = selectedCountry.countryCode as CountryCode;
  const selectedCallingCode = selectedCountry.countryCallingCode;
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 100);
  }, [selectedCountry]);

  const isValid = React.useMemo(() => {
    if (!sanitizedNumber) return false;

    return isValidPhoneNumber(`+${selectedCallingCode}${sanitizedNumber}`, selectedCountryCode);
  }, [sanitizedNumber, selectedCountryCode, selectedCallingCode]);

  const isDisabled = !isValid || createSequence.isLoading || createSequence.isSuccess;

  const placeholder = React.useMemo(() => {
    return isSupportedCountry(selectedCountryCode)
      ? getExampleNumber(selectedCountryCode, examples)?.formatNational()
      : getExampleNumber('US', examples).formatNational();
  }, [selectedCountryCode]);

  const onSubmit = async () => {
    if (isDisabled) return;

    createSequence.mutate(
      { tel: `${selectedCallingCode}${sanitizedNumber}` },
      {
        onSuccess: ({ sequence_id }) => {
          router.push(`/verify/${sequence_id}`);
        },
      },
    );
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen space-y-12 font-sans antialiased select-none">
      <Card emoji="🔑" title="Sign in with your mobile number" subtitle="You'll receive a unique Authmoji sequence">
        <div className="relative mt-8 rounded-md shadow-sm w-72">
          <CountrySelect value={selectedCountry} onChange={setSeletedCountry} />

          <input
            ref={inputRef}
            type="tel"
            name="phone-number"
            autoComplete="tel-national"
            id="phone-number"
            className={cn('block w-full py-4 pl-24 border-gray-300 appearance-none rounded-md focus:outline-none', {
              'border-green-500 focus:border-green-500 focus:ring-green-500': isValid,
              'border-red-500 focus:ring-indigo-500 focus:border-indigo-500': sanitizedNumber && !isValid,
            })}
            placeholder={placeholder}
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.currentTarget.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
          />
        </div>

        {createSequence.error && <div className="mt-6 text-red-500">{createSequence.error.message}</div>}

        <Button className="mt-6" disabled={isDisabled} onClick={onSubmit}>
          {createSequence.isLoading ? 'Sending...' : 'Submit'}
        </Button>
      </Card>
    </div>
  );
}
