export const countries = [
  {
    countryNameEn: 'United States',
    countryNameLocal: 'United States',
    countryCode: 'US',
    region: 'North America',
    flag: '🇺🇸',
    countryCallingCode: '1',
  },
  {
    countryNameEn: 'Canada',
    countryNameLocal: 'Canada',
    countryCode: 'CA',
    region: 'North America',
    flag: '🇨🇦',
    countryCallingCode: '1',
  },
  {
    countryNameEn: 'United Kingdom',
    countryNameLocal: 'Great Britain',
    countryCode: 'GB',
    region: 'Europe',
    flag: '🇬🇧',
    countryCallingCode: '44',
  },
  {
    countryNameEn: 'France',
    countryNameLocal: 'France',
    countryCode: 'FR',
    region: 'Europe',
    flag: '🇫🇷',
    countryCallingCode: '33',
  },
  {
    countryNameEn: 'Germany',
    countryNameLocal: 'Deutschland',
    countryCode: 'DE',
    region: 'Europe',
    flag: '🇩🇪',
    countryCallingCode: '49',
  },
  {
    countryNameEn: 'Spain',
    countryNameLocal: 'España',
    countryCode: 'ES',
    region: 'Europe',
    flag: '🇪🇸',
    countryCallingCode: '34',
  },

  //
  {
    countryNameEn: 'Afghanistan',
    countryNameLocal: 'د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان',
    countryCode: 'AF',
    region: 'Asia & Pacific',
    flag: '🇦🇫',
    countryCallingCode: '93',
  },
  {
    countryNameEn: 'Albania',
    countryNameLocal: 'Shqipëria',
    countryCode: 'AL',
    region: 'Europe',
    flag: '🇦🇱',
    countryCallingCode: '355',
  },
  {
    countryNameEn: 'Algeria',
    countryNameLocal: 'الجزائر',
    countryCode: 'DZ',
    region: 'Arab States',
    flag: '🇩🇿',
    countryCallingCode: '213',
  },
  {
    countryNameEn: 'American Samoa',
    countryNameLocal: 'American Samoa',
    countryCode: 'AS',
    region: 'Asia & Pacific',
    flag: '🇦🇸',
    countryCallingCode: '1684',
  },
  {
    countryNameEn: 'Andorra',
    countryNameLocal: 'Andorra',
    countryCode: 'AD',
    region: 'Europe',
    flag: '🇦🇩',
    countryCallingCode: '376',
  },
  {
    countryNameEn: 'Angola',
    countryNameLocal: 'Angola',
    countryCode: 'AO',
    region: 'Africa',
    flag: '🇦🇴',
    countryCallingCode: '244',
  },
  {
    countryNameEn: 'Anguilla',
    countryNameLocal: 'Anguilla',
    countryCode: 'AI',
    region: 'South/Latin America',
    flag: '🇦🇮',
    countryCallingCode: '1264',
  },
  {
    countryNameEn: 'Antigua and Barbuda',
    countryNameLocal: 'Antigua and Barbuda',
    countryCode: 'AG',
    region: 'South/Latin America',
    flag: '🇦🇬',
    countryCallingCode: '1268',
  },
  {
    countryNameEn: 'Argentina',
    countryNameLocal: 'Argentina',
    countryCode: 'AR',
    region: 'South/Latin America',
    flag: '🇦🇷',
    countryCallingCode: '54',
  },
  {
    countryNameEn: 'Armenia',
    countryNameLocal: 'Հայաստան',
    countryCode: 'AM',
    region: 'Europe',
    flag: '🇦🇲',
    countryCallingCode: '374',
  },
  {
    countryNameEn: 'Aruba',
    countryNameLocal: 'Aruba',
    countryCode: 'AW',
    region: 'South/Latin America',
    flag: '🇦🇼',
    countryCallingCode: '297',
  },
  {
    countryNameEn: 'Australia',
    countryNameLocal: 'Australia',
    countryCode: 'AU',
    region: 'Asia & Pacific',
    flag: '🇦🇺',
    countryCallingCode: '61',
  },
  {
    countryNameEn: 'Austria',
    countryNameLocal: 'Österreich',
    countryCode: 'AT',
    region: 'Europe',
    flag: '🇦🇹',
    countryCallingCode: '43',
  },
  {
    countryNameEn: 'Azerbaijan',
    countryNameLocal: 'Azərbaycan',
    countryCode: 'AZ',
    region: 'Asia & Pacific',
    flag: '🇦🇿',
    countryCallingCode: '994',
  },
  {
    countryNameEn: 'Bahrain',
    countryNameLocal: 'البحرين',
    countryCode: 'BH',
    region: 'Arab States',
    flag: '🇧🇭',
    countryCallingCode: '973',
  },
  {
    countryNameEn: 'Bangladesh',
    countryNameLocal: 'গণপ্রজাতন্ত্রী বাংলাদেশ',
    countryCode: 'BD',
    region: 'Asia & Pacific',
    flag: '🇧🇩',
    countryCallingCode: '880',
  },
  {
    countryNameEn: 'Barbados',
    countryNameLocal: 'Barbados',
    countryCode: 'BB',
    region: 'South/Latin America',
    flag: '🇧🇧',
    countryCallingCode: '1246',
  },
  {
    countryNameEn: 'Belarus',
    countryNameLocal: 'Беларусь',
    countryCode: 'BY',
    region: 'Europe',
    flag: '🇧🇾',
    countryCallingCode: '375',
  },
  {
    countryNameEn: 'Belgium',
    countryNameLocal: 'België, Belgique, Belgien',
    countryCode: 'BE',
    region: 'Europe',
    flag: '🇧🇪',
    countryCallingCode: '32',
  },
  {
    countryNameEn: 'Belize',
    countryNameLocal: 'Belize',
    countryCode: 'BZ',
    region: 'South/Latin America',
    flag: '🇧🇿',
    countryCallingCode: '501',
  },
  {
    countryNameEn: 'Benin',
    countryNameLocal: 'Bénin',
    countryCode: 'BJ',
    region: 'Africa',
    flag: '🇧🇯',
    countryCallingCode: '229',
  },
  {
    countryNameEn: 'Bermuda',
    countryNameLocal: 'Bermuda',
    countryCode: 'BM',
    region: 'North America',
    flag: '🇧🇲',
    countryCallingCode: '1441',
  },
  {
    countryNameEn: 'Bhutan',
    countryNameLocal: 'འབྲུག་ཡུལ',
    countryCode: 'BT',
    region: 'Asia & Pacific',
    flag: '🇧🇹',
    countryCallingCode: '975',
  },
  {
    countryNameEn: 'Bolivia (Plurinational State of)',
    countryNameLocal: 'Bolivia, Bulibiya, Volívia, Wuliwya',
    countryCode: 'BO',
    region: 'South/Latin America',
    flag: '🇧🇴',
    countryCallingCode: '591',
  },
  {
    countryNameEn: 'Bonaire, Sint Eustatius and Saba',
    countryNameLocal: 'Caribisch Nederland',
    countryCode: 'BQ',
    region: 'Unknown',
    flag: '🇧🇶',
    countryCallingCode: '5997',
  },
  {
    countryNameEn: 'Bosnia and Herzegovina',
    countryNameLocal: 'Bosna i Hercegovina',
    countryCode: 'BA',
    region: 'Europe',
    flag: '🇧🇦',
    countryCallingCode: '387',
  },
  {
    countryNameEn: 'Botswana',
    countryNameLocal: 'Botswana',
    countryCode: 'BW',
    region: 'Africa',
    flag: '🇧🇼',
    countryCallingCode: '267',
  },
  {
    countryNameEn: 'Brazil',
    countryNameLocal: 'Brasil',
    countryCode: 'BR',
    region: 'South/Latin America',
    flag: '🇧🇷',
    countryCallingCode: '55',
  },
  {
    countryNameEn: 'British Indian Ocean Territory',
    countryNameLocal: 'British Indian Ocean Territory',
    countryCode: 'IO',
    region: 'Indian Ocean',
    flag: '🇮🇴',
    countryCallingCode: '246',
  },
  {
    countryNameEn: 'Brunei Darussalam',
    countryNameLocal: 'Brunei Darussalam',
    countryCode: 'BN',
    region: 'Asia & Pacific',
    flag: '🇧🇳',
    countryCallingCode: '673',
  },
  {
    countryNameEn: 'Bulgaria',
    countryNameLocal: 'България',
    countryCode: 'BG',
    region: 'Europe',
    flag: '🇧🇬',
    countryCallingCode: '359',
  },
  {
    countryNameEn: 'Burkina Faso',
    countryNameLocal: 'Burkina Faso',
    countryCode: 'BF',
    region: 'Africa',
    flag: '🇧🇫',
    countryCallingCode: '226',
  },
  {
    countryNameEn: 'Burundi',
    countryNameLocal: 'Burundi',
    countryCode: 'BI',
    region: 'Africa',
    flag: '🇧🇮',
    countryCallingCode: '257',
  },
  {
    countryNameEn: 'Cabo Verde',
    countryNameLocal: 'Cabo Verde',
    countryCode: 'CV',
    region: 'Africa',
    flag: '🇨🇻',
    countryCallingCode: '238',
  },
  {
    countryNameEn: 'Cambodia',
    countryNameLocal: 'កម្ពុជា',
    countryCode: 'KH',
    region: 'Asia & Pacific',
    flag: '🇰🇭',
    countryCallingCode: '855',
  },
  {
    countryNameEn: 'Cameroon',
    countryNameLocal: 'Cameroun, Cameroon',
    countryCode: 'CM',
    region: 'Africa',
    flag: '🇨🇲',
    countryCallingCode: '237',
  },
  {
    countryNameEn: 'Cayman Islands',
    countryNameLocal: 'Cayman Islands',
    countryCode: 'KY',
    region: 'Caribbean Sea',
    flag: '🇰🇾',
    countryCallingCode: '1 345',
  },
  {
    countryNameEn: 'Central African Republic',
    countryNameLocal: 'République centrafricaine',
    countryCode: 'CF',
    region: 'Africa',
    flag: '🇨🇫',
    countryCallingCode: '236',
  },
  {
    countryNameEn: 'Chad',
    countryNameLocal: 'Tchad, تشاد',
    countryCode: 'TD',
    region: 'Africa',
    flag: '🇹🇩',
    countryCallingCode: '235',
  },
  {
    countryNameEn: 'Chile',
    countryNameLocal: 'Chile',
    countryCode: 'CL',
    region: 'South/Latin America',
    flag: '🇨🇱',
    countryCallingCode: '56',
  },
  {
    countryNameEn: 'China',
    countryNameLocal: '中国',
    countryCode: 'CN',
    region: 'Asia & Pacific',
    flag: '🇨🇳',
    countryCallingCode: '86',
  },
  {
    countryNameEn: 'Christmas Island',
    countryNameLocal: 'Christmas Island',
    countryCode: 'CX',
    region: 'Asia & Pacific',
    flag: '🇨🇽',
    countryCallingCode: '61',
  },
  {
    countryNameEn: 'Cocos (Keeling) Islands',
    countryNameLocal: 'Pulu Kokos (Keeling)',
    countryCode: 'CC',
    region: 'Australia',
    flag: '🇨🇨',
    countryCallingCode: '61 891',
  },
  {
    countryNameEn: 'Colombia',
    countryNameLocal: 'Colombia',
    countryCode: 'CO',
    region: 'South/Latin America',
    flag: '🇨🇴',
    countryCallingCode: '57',
  },
  {
    countryNameEn: 'Commonwealth of The Bahamas',
    countryNameLocal: 'Commonwealth of The Bahamas',
    countryCode: 'BS',
    region: 'Caribbean',
    flag: '🇧🇸',
    countryCallingCode: '1 242',
  },
  {
    countryNameEn: 'Commonwealth of the Northern Mariana Islands',
    countryNameLocal: 'Sankattan Siha Na Islas Mariånas',
    countryCode: 'MP',
    region: 'Pacific Ocean',
    flag: '🇲🇵',
    countryCallingCode: '1 670',
  },
  {
    countryNameEn: 'Comoros',
    countryNameLocal: 'Umoja wa Komori',
    countryCode: 'KM',
    region: 'Indian Ocean',
    flag: '🇰🇲',
    countryCallingCode: '269',
  },
  {
    countryNameEn: 'Cook Islands',
    countryNameLocal: "Kūki 'Āirani",
    countryCode: 'CK',
    region: 'South Pacific Ocean',
    flag: '🇨🇰',
    countryCallingCode: '682',
  },
  {
    countryNameEn: 'Costa Rica',
    countryNameLocal: 'Costa Rica',
    countryCode: 'CR',
    region: 'South/Latin America',
    flag: '🇨🇷',
    countryCallingCode: '506',
  },
  {
    countryNameEn: 'Croatia',
    countryNameLocal: 'Hrvatska',
    countryCode: 'HR',
    region: 'Europe',
    flag: '🇭🇷',
    countryCallingCode: '385',
  },
  {
    countryNameEn: 'Cuba',
    countryNameLocal: 'Cuba',
    countryCode: 'CU',
    region: 'South/Latin America',
    flag: '🇨🇺',
    countryCallingCode: '53',
  },
  {
    countryNameEn: 'Curaçao',
    countryNameLocal: 'Curaçao',
    countryCode: 'CW',
    region: 'Unknown',
    flag: '🇨🇼',
    countryCallingCode: '599',
  },
  {
    countryNameEn: 'Cyprus',
    countryNameLocal: 'Κύπρος, Kibris',
    countryCode: 'CY',
    region: 'Europe',
    flag: '🇨🇾',
    countryCallingCode: '357',
  },
  {
    countryNameEn: 'Czechia',
    countryNameLocal: 'Česká republika',
    countryCode: 'CZ',
    region: 'Europe',
    flag: '🇨🇿',
    countryCallingCode: '420',
  },
  {
    countryNameEn: "Côte d'Ivoire",
    countryNameLocal: "Côte d'Ivoire",
    countryCode: 'CI',
    region: 'Africa',
    flag: '🇨🇮',
    countryCallingCode: '225',
  },
  {
    countryNameEn: 'Democratic Republic of the Congo',
    countryNameLocal: 'Democratic Republic of the Congo',
    countryCode: 'CD',
    region: 'Africa',
    flag: '🇨🇩',
    countryCallingCode: '243',
  },
  {
    countryNameEn: 'Denmark',
    countryNameLocal: 'Danmark',
    countryCode: 'DK',
    region: 'Europe',
    flag: '🇩🇰',
    countryCallingCode: '45',
  },
  {
    countryNameEn: 'Djibouti',
    countryNameLocal: 'Djibouti, جيبوتي, Jabuuti, Gabuutih',
    countryCode: 'DJ',
    region: 'Arab States',
    flag: '🇩🇯',
    countryCallingCode: '253',
  },
  {
    countryNameEn: 'Dominica',
    countryNameLocal: 'Dominica',
    countryCode: 'DM',
    region: 'South/Latin America',
    flag: '🇩🇲',
    countryCallingCode: '767',
  },
  {
    countryNameEn: 'Dominican Republic',
    countryNameLocal: 'República Dominicana',
    countryCode: 'DO',
    region: 'South/Latin America',
    flag: '🇩🇴',
    countryCallingCode: '1',
  },
  {
    countryNameEn: 'Ecuador',
    countryNameLocal: 'Ecuador',
    countryCode: 'EC',
    region: 'South/Latin America',
    flag: '🇪🇨',
    countryCallingCode: '593',
  },
  {
    countryNameEn: 'Egypt',
    countryNameLocal: 'مصر',
    countryCode: 'EG',
    region: 'Arab States',
    flag: '🇪🇬',
    countryCallingCode: '20',
  },
  {
    countryNameEn: 'El Salvador',
    countryNameLocal: 'El Salvador',
    countryCode: 'SV',
    region: 'South/Latin America',
    flag: '🇸🇻',
    countryCallingCode: '503',
  },
  {
    countryNameEn: 'Equatorial Guinea',
    countryNameLocal: 'Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial',
    countryCode: 'GQ',
    region: 'Africa',
    flag: '🇬🇶',
    countryCallingCode: '240',
  },
  {
    countryNameEn: 'Eritrea',
    countryNameLocal: 'ኤርትራ, إرتريا, Eritrea',
    countryCode: 'ER',
    region: 'Africa',
    flag: '🇪🇷',
    countryCallingCode: '291',
  },
  {
    countryNameEn: 'Estonia',
    countryNameLocal: 'Eesti',
    countryCode: 'EE',
    region: 'Europe',
    flag: '🇪🇪',
    countryCallingCode: '372',
  },
  {
    countryNameEn: 'Eswatini',
    countryNameLocal: 'Swaziland',
    countryCode: 'SZ',
    region: 'Africa',
    flag: '🇸🇿',
    countryCallingCode: '268',
  },
  {
    countryNameEn: 'Ethiopia',
    countryNameLocal: 'ኢትዮጵያ, Itoophiyaa',
    countryCode: 'ET',
    region: 'Africa',
    flag: '🇪🇹',
    countryCallingCode: '251',
  },
  {
    countryNameEn: 'Falkland Islands',
    countryNameLocal: 'Falkland Islands',
    countryCode: 'FK',
    region: 'South Atlantic Ocean',
    flag: '🇫🇰',
    countryCallingCode: '500',
  },
  {
    countryNameEn: 'Faroe Islands',
    countryNameLocal: 'Færøerne',
    countryCode: 'FO',
    region: 'Europe',
    flag: '🇫🇴',
    countryCallingCode: '298',
  },
  {
    countryNameEn: 'Fiji',
    countryNameLocal: 'Fiji',
    countryCode: 'FJ',
    region: 'Asia & Pacific',
    flag: '🇫🇯',
    countryCallingCode: '679',
  },
  {
    countryNameEn: 'Finland',
    countryNameLocal: 'Suomi',
    countryCode: 'FI',
    region: 'Europe',
    flag: '🇫🇮',
    countryCallingCode: '358',
  },
  {
    countryNameEn: 'French Guiana',
    countryNameLocal: 'Guyane française',
    countryCode: 'GF',
    region: 'South/Latin America',
    flag: '🇬🇫',
    countryCallingCode: '594',
  },
  {
    countryNameEn: 'French Polynesia',
    countryNameLocal: 'Polynésie française',
    countryCode: 'PF',
    region: 'Asia & Pacific',
    flag: '🇵🇫',
    countryCallingCode: '689',
  },
  {
    countryNameEn: 'Gabon',
    countryNameLocal: 'Gabon',
    countryCode: 'GA',
    region: 'Africa',
    flag: '🇬🇦',
    countryCallingCode: '241',
  },
  {
    countryNameEn: 'Gambia',
    countryNameLocal: 'The Gambia',
    countryCode: 'GM',
    region: 'Africa',
    flag: '🇬🇲',
    countryCallingCode: '220',
  },
  {
    countryNameEn: 'Georgia',
    countryNameLocal: 'საქართველო',
    countryCode: 'GE',
    region: 'Europe',
    flag: '🇬🇪',
    countryCallingCode: '995',
  },
  {
    countryNameEn: 'Ghana',
    countryNameLocal: 'Ghana',
    countryCode: 'GH',
    region: 'Africa',
    flag: '🇬🇭',
    countryCallingCode: '233',
  },
  {
    countryNameEn: 'Gibraltar',
    countryNameLocal: 'Gibraltar',
    countryCode: 'GI',
    region: 'Europe',
    flag: '🇬🇮',
    countryCallingCode: '350',
  },
  {
    countryNameEn: 'Greece',
    countryNameLocal: 'Ελλάδα',
    countryCode: 'GR',
    region: 'Europe',
    flag: '🇬🇷',
    countryCallingCode: '30',
  },
  {
    countryNameEn: 'Greenland',
    countryNameLocal: 'Kalaallit Nunaat, Grønland',
    countryCode: 'GL',
    region: 'Europe',
    flag: '🇬🇱',
    countryCallingCode: '299',
  },
  {
    countryNameEn: 'Grenada',
    countryNameLocal: 'Grenada',
    countryCode: 'GD',
    region: 'South/Latin America',
    flag: '🇬🇩',
    countryCallingCode: '1473',
  },
  {
    countryNameEn: 'Guadeloupe',
    countryNameLocal: 'Guadeloupe',
    countryCode: 'GP',
    region: 'South/Latin America',
    flag: '🇬🇵',
    countryCallingCode: '590',
  },
  {
    countryNameEn: 'Guam',
    countryNameLocal: 'Guam, Guåhån',
    countryCode: 'GU',
    region: 'Asia & Pacific',
    flag: '🇬🇺',
    countryCallingCode: '1',
  },
  {
    countryNameEn: 'Guatemala',
    countryNameLocal: 'Guatemala',
    countryCode: 'GT',
    region: 'South/Latin America',
    flag: '🇬🇹',
    countryCallingCode: '502',
  },
  {
    countryNameEn: 'Guernsey',
    countryNameLocal: 'Guernsey',
    countryCode: 'GG',
    region: 'Europe',
    flag: '🇬🇬',
    countryCallingCode: '44',
  },
  {
    countryNameEn: 'Guinea',
    countryNameLocal: 'Guinée',
    countryCode: 'GN',
    region: 'Africa',
    flag: '🇬🇳',
    countryCallingCode: '224',
  },
  {
    countryNameEn: 'Guinea-Bissau',
    countryNameLocal: 'Guiné-Bissau',
    countryCode: 'GW',
    region: 'Africa',
    flag: '🇬🇼',
    countryCallingCode: '245',
  },
  {
    countryNameEn: 'Guyana',
    countryNameLocal: 'Guyana',
    countryCode: 'GY',
    region: 'South/Latin America',
    flag: '🇬🇾',
    countryCallingCode: '592',
  },
  {
    countryNameEn: 'Haiti',
    countryNameLocal: 'Haïti, Ayiti',
    countryCode: 'HT',
    region: 'South/Latin America',
    flag: '🇭🇹',
    countryCallingCode: '509',
  },
  {
    countryNameEn: 'Holy See',
    countryNameLocal: 'Sancta Sedes',
    countryCode: 'VA',
    region: 'Europe',
    flag: '🇻🇦',
    countryCallingCode: '39',
  },
  {
    countryNameEn: 'Honduras',
    countryNameLocal: 'Honduras',
    countryCode: 'HN',
    region: 'South/Latin America',
    flag: '🇭🇳',
    countryCallingCode: '504',
  },
  {
    countryNameEn: 'Hong Kong',
    countryNameLocal: '香港, Hong Kong',
    countryCode: 'HK',
    region: 'Asia & Pacific',
    flag: '🇭🇰',
    countryCallingCode: '852',
  },
  {
    countryNameEn: 'Hungary',
    countryNameLocal: 'Magyarország',
    countryCode: 'HU',
    region: 'Europe',
    flag: '🇭🇺',
    countryCallingCode: '36',
  },
  {
    countryNameEn: 'Iceland',
    countryNameLocal: 'Ísland',
    countryCode: 'IS',
    region: 'Europe',
    flag: '🇮🇸',
    countryCallingCode: '354',
  },
  {
    countryNameEn: 'India',
    countryNameLocal: 'भारत, India',
    countryCode: 'IN',
    region: 'Asia & Pacific',
    flag: '🇮🇳',
    countryCallingCode: '91',
  },
  {
    countryNameEn: 'Indonesia',
    countryNameLocal: 'Indonesia',
    countryCode: 'ID',
    region: 'Asia & Pacific',
    flag: '🇮🇩',
    countryCallingCode: '62',
  },
  {
    countryNameEn: 'Iran (Islamic Republic of)',
    countryNameLocal: 'ایران',
    countryCode: 'IR',
    region: 'Asia & Pacific',
    flag: '🇮🇷',
    countryCallingCode: '98',
  },
  {
    countryNameEn: 'Iraq',
    countryNameLocal: 'العراق, Iraq',
    countryCode: 'IQ',
    region: 'Arab States',
    flag: '🇮🇶',
    countryCallingCode: '964',
  },
  {
    countryNameEn: 'Ireland',
    countryNameLocal: 'Ireland, Éire',
    countryCode: 'IE',
    region: 'Europe',
    flag: '🇮🇪',
    countryCallingCode: '353',
  },
  {
    countryNameEn: 'Isle of Man',
    countryNameLocal: 'Isle of Man',
    countryCode: 'IM',
    region: 'Europe',
    flag: '🇮🇲',
    countryCallingCode: '44',
  },
  {
    countryNameEn: 'Israel',
    countryNameLocal: 'ישראל',
    countryCode: 'IL',
    region: 'Europe',
    flag: '🇮🇱',
    countryCallingCode: '972',
  },
  {
    countryNameEn: 'Italy',
    countryNameLocal: 'Italia',
    countryCode: 'IT',
    region: 'Europe',
    flag: '🇮🇹',
    countryCallingCode: '39',
  },
  {
    countryNameEn: 'Jamaica',
    countryNameLocal: 'Jamaica',
    countryCode: 'JM',
    region: 'South/Latin America',
    flag: '🇯🇲',
    countryCallingCode: '876',
  },
  {
    countryNameEn: 'Japan',
    countryNameLocal: '日本',
    countryCode: 'JP',
    region: 'Asia & Pacific',
    flag: '🇯🇵',
    countryCallingCode: '81',
  },
  {
    countryNameEn: 'Jersey',
    countryNameLocal: 'Jersey',
    countryCode: 'JE',
    region: 'Europe',
    flag: '🇯🇪',
    countryCallingCode: '44',
  },
  {
    countryNameEn: 'Jordan',
    countryNameLocal: 'الأُرْدُن',
    countryCode: 'JO',
    region: 'Arab States',
    flag: '🇯🇴',
    countryCallingCode: '962',
  },
  {
    countryNameEn: 'Kazakhstan',
    countryNameLocal: 'Қазақстан, Казахстан',
    countryCode: 'KZ',
    region: 'Asia & Pacific',
    flag: '🇰🇿',
    countryCallingCode: '7',
  },
  {
    countryNameEn: 'Kenya',
    countryNameLocal: 'Kenya',
    countryCode: 'KE',
    region: 'Africa',
    flag: '🇰🇪',
    countryCallingCode: '254',
  },
  {
    countryNameEn: 'Kiribati',
    countryNameLocal: 'Kiribati',
    countryCode: 'KI',
    region: 'Asia & Pacific',
    flag: '🇰🇮',
    countryCallingCode: '686',
  },
  {
    countryNameEn: 'Kuwait',
    countryNameLocal: 'الكويت',
    countryCode: 'KW',
    region: 'Arab States',
    flag: '🇰🇼',
    countryCallingCode: '965',
  },
  {
    countryNameEn: 'Kyrgyzstan',
    countryNameLocal: 'Кыргызстан, Киргизия',
    countryCode: 'KG',
    region: 'Asia & Pacific',
    flag: '🇰🇬',
    countryCallingCode: '996',
  },
  {
    countryNameEn: "Lao People's Democratic Republic",
    countryNameLocal: 'ປະຊາຊົນລາວ',
    countryCode: 'LA',
    region: 'Asia & Pacific',
    flag: '🇱🇦',
    countryCallingCode: '856',
  },
  {
    countryNameEn: 'Latvia',
    countryNameLocal: 'Latvija',
    countryCode: 'LV',
    region: 'Europe',
    flag: '🇱🇻',
    countryCallingCode: '371',
  },
  {
    countryNameEn: 'Lebanon',
    countryNameLocal: 'لبنان, Liban',
    countryCode: 'LB',
    region: 'Arab States',
    flag: '🇱🇧',
    countryCallingCode: '961',
  },
  {
    countryNameEn: 'Lesotho',
    countryNameLocal: 'Lesotho',
    countryCode: 'LS',
    region: 'Africa',
    flag: '🇱🇸',
    countryCallingCode: '266',
  },
  {
    countryNameEn: 'Liberia',
    countryNameLocal: 'Liberia',
    countryCode: 'LR',
    region: 'Africa',
    flag: '🇱🇷',
    countryCallingCode: '231',
  },
  {
    countryNameEn: 'Libya',
    countryNameLocal: 'ليبيا',
    countryCode: 'LY',
    region: 'Arab States',
    flag: '🇱🇾',
    countryCallingCode: '218',
  },
  {
    countryNameEn: 'Liechtenstein',
    countryNameLocal: 'Liechtenstein',
    countryCode: 'LI',
    region: 'Europe',
    flag: '🇱🇮',
    countryCallingCode: '423',
  },
  {
    countryNameEn: 'Lithuania',
    countryNameLocal: 'Lietuva',
    countryCode: 'LT',
    region: 'Europe',
    flag: '🇱🇹',
    countryCallingCode: '370',
  },
  {
    countryNameEn: 'Luxembourg',
    countryNameLocal: 'Lëtzebuerg, Luxembourg, Luxemburg',
    countryCode: 'LU',
    region: 'Europe',
    flag: '🇱🇺',
    countryCallingCode: '352',
  },
  {
    countryNameEn: 'Macao',
    countryNameLocal: '澳門, Macau',
    countryCode: 'MO',
    region: 'Asia & Pacific',
    flag: '🇲🇴',
    countryCallingCode: '853',
  },
  {
    countryNameEn: 'Madagascar',
    countryNameLocal: 'Madagasikara, Madagascar',
    countryCode: 'MG',
    region: 'Africa',
    flag: '🇲🇬',
    countryCallingCode: '261',
  },
  {
    countryNameEn: 'Malawi',
    countryNameLocal: 'Malawi',
    countryCode: 'MW',
    region: 'Africa',
    flag: '🇲🇼',
    countryCallingCode: '265',
  },
  {
    countryNameEn: 'Malaysia',
    countryNameLocal: '',
    countryCode: 'MY',
    region: 'Asia & Pacific',
    flag: '🇲🇾',
    countryCallingCode: '60',
  },
  {
    countryNameEn: 'Maldives',
    countryNameLocal: '',
    countryCode: 'MV',
    region: 'Asia & Pacific',
    flag: '🇲🇻',
    countryCallingCode: '960',
  },
  {
    countryNameEn: 'Mali',
    countryNameLocal: 'Mali',
    countryCode: 'ML',
    region: 'Africa',
    flag: '🇲🇱',
    countryCallingCode: '223',
  },
  {
    countryNameEn: 'Malta',
    countryNameLocal: 'Malta',
    countryCode: 'MT',
    region: 'Europe',
    flag: '🇲🇹',
    countryCallingCode: '356',
  },
  {
    countryNameEn: 'Martinique',
    countryNameLocal: 'Martinique',
    countryCode: 'MQ',
    region: 'South/Latin America',
    flag: '🇲🇶',
    countryCallingCode: '596',
  },
  {
    countryNameEn: 'Mauritania',
    countryNameLocal: 'موريتانيا, Mauritanie',
    countryCode: 'MR',
    region: 'Arab States',
    flag: '🇲🇷',
    countryCallingCode: '222',
  },
  {
    countryNameEn: 'Mauritius',
    countryNameLocal: 'Maurice, Mauritius',
    countryCode: 'MU',
    region: 'Africa',
    flag: '🇲🇺',
    countryCallingCode: '230',
  },
  {
    countryNameEn: 'Mayotte',
    countryNameLocal: 'Mayotte',
    countryCode: 'YT',
    region: 'Africa',
    flag: '🇾🇹',
    countryCallingCode: '262',
  },
  {
    countryNameEn: 'Mexico',
    countryNameLocal: 'México',
    countryCode: 'MX',
    region: 'South/Latin America',
    flag: '🇲🇽',
    countryCallingCode: '52',
  },
  {
    countryNameEn: 'Micronesia (Federated States of)',
    countryNameLocal: 'Micronesia',
    countryCode: 'FM',
    region: 'Asia & Pacific',
    flag: '🇫🇲',
    countryCallingCode: '691',
  },
  {
    countryNameEn: 'Monaco',
    countryNameLocal: 'Monaco',
    countryCode: 'MC',
    region: 'Europe',
    flag: '🇲🇨',
    countryCallingCode: '377',
  },
  {
    countryNameEn: 'Mongolia',
    countryNameLocal: 'Монгол Улс',
    countryCode: 'MN',
    region: 'Asia & Pacific',
    flag: '🇲🇳',
    countryCallingCode: '976',
  },
  {
    countryNameEn: 'Montenegro',
    countryNameLocal: 'Crna Gora, Црна Гора',
    countryCode: 'ME',
    region: 'Europe',
    flag: '🇲🇪',
    countryCallingCode: '382',
  },
  {
    countryNameEn: 'Montserrat',
    countryNameLocal: 'Montserrat',
    countryCode: 'MS',
    region: 'South/Latin America',
    flag: '🇲🇸',
    countryCallingCode: '1664',
  },
  {
    countryNameEn: 'Morocco',
    countryNameLocal: 'Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب',
    countryCode: 'MA',
    region: 'Arab States',
    flag: '🇲🇦',
    countryCallingCode: '212',
  },
  {
    countryNameEn: 'Mozambique',
    countryNameLocal: 'Mozambique',
    countryCode: 'MZ',
    region: 'Africa',
    flag: '🇲🇿',
    countryCallingCode: '258',
  },
  {
    countryNameEn: 'Myanmar',
    countryNameLocal: 'မြန်မာ',
    countryCode: 'MM',
    region: 'Asia & Pacific',
    flag: '🇲🇲',
    countryCallingCode: '95',
  },
  {
    countryNameEn: 'Namibia',
    countryNameLocal: 'Namibia',
    countryCode: 'NA',
    region: 'Africa',
    flag: '🇳🇦',
    countryCallingCode: '264',
  },
  {
    countryNameEn: 'Nauru',
    countryNameLocal: 'Nauru',
    countryCode: 'NR',
    region: 'Asia & Pacific',
    flag: '🇳🇷',
    countryCallingCode: '674',
  },
  {
    countryNameEn: 'Nepal',
    countryNameLocal: '',
    countryCode: 'NP',
    region: 'Asia & Pacific',
    flag: '🇳🇵',
    countryCallingCode: '977',
  },
  {
    countryNameEn: 'Netherlands',
    countryNameLocal: 'Nederland',
    countryCode: 'NL',
    region: 'Europe',
    flag: '🇳🇱',
    countryCallingCode: '31',
  },
  {
    countryNameEn: 'New Caledonia',
    countryNameLocal: 'Nouvelle-Calédonie',
    countryCode: 'NC',
    region: 'Asia & Pacific',
    flag: '🇳🇨',
    countryCallingCode: '687',
  },
  {
    countryNameEn: 'New Zealand',
    countryNameLocal: 'New Zealand',
    countryCode: 'NZ',
    region: 'Asia & Pacific',
    flag: '🇳🇿',
    countryCallingCode: '64',
  },
  {
    countryNameEn: 'Nicaragua',
    countryNameLocal: 'Nicaragua',
    countryCode: 'NI',
    region: 'South/Latin America',
    flag: '🇳🇮',
    countryCallingCode: '505',
  },
  {
    countryNameEn: 'Niger',
    countryNameLocal: 'Niger',
    countryCode: 'NE',
    region: 'Africa',
    flag: '🇳🇪',
    countryCallingCode: '227',
  },
  {
    countryNameEn: 'Nigeria',
    countryNameLocal: 'Nigeria',
    countryCode: 'NG',
    region: 'Africa',
    flag: '🇳🇬',
    countryCallingCode: '234',
  },
  {
    countryNameEn: 'Niue',
    countryNameLocal: 'Niue',
    countryCode: 'NU',
    region: 'Asia & Pacific',
    flag: '🇳🇺',
    countryCallingCode: '683',
  },
  {
    countryNameEn: 'Norfolk Island',
    countryNameLocal: 'Norfolk Island',
    countryCode: 'NF',
    region: 'Asia & Pacific',
    flag: '🇳🇫',
    countryCallingCode: '672',
  },
  {
    countryNameEn: 'North Korea',
    countryNameLocal: '조선민주주의인민공화국',
    countryCode: 'KP',
    region: 'Asia',
    flag: '🇰🇵',
    countryCallingCode: '850',
  },
  {
    countryNameEn: 'North Macedonia',
    countryNameLocal: 'Македонија',
    countryCode: 'MK',
    region: 'Europe',
    flag: '🇲🇰',
    countryCallingCode: '389',
  },
  {
    countryNameEn: 'Norway',
    countryNameLocal: 'Norge, Noreg',
    countryCode: 'NO',
    region: 'Europe',
    flag: '🇳🇴',
    countryCallingCode: '47',
  },
  {
    countryNameEn: 'Oman',
    countryNameLocal: 'سلطنة عُمان',
    countryCode: 'OM',
    region: 'Arab States',
    flag: '🇴🇲',
    countryCallingCode: '968',
  },
  {
    countryNameEn: 'Pakistan',
    countryNameLocal: 'پاکستان',
    countryCode: 'PK',
    region: 'Asia & Pacific',
    flag: '🇵🇰',
    countryCallingCode: '92',
  },
  {
    countryNameEn: 'Palau',
    countryNameLocal: 'Palau',
    countryCode: 'PW',
    region: 'Asia & Pacific',
    flag: '🇵🇼',
    countryCallingCode: '680',
  },
  {
    countryNameEn: 'Palestine, State of',
    countryNameLocal: 'Palestinian Territory',
    countryCode: 'PS',
    region: 'Arab States',
    flag: '🇵🇸',
    countryCallingCode: '970',
  },
  {
    countryNameEn: 'Panama',
    countryNameLocal: 'Panama',
    countryCode: 'PA',
    region: 'South/Latin America',
    flag: '🇵🇦',
    countryCallingCode: '507',
  },
  {
    countryNameEn: 'Papua New Guinea',
    countryNameLocal: 'Papua New Guinea',
    countryCode: 'PG',
    region: 'Asia & Pacific',
    flag: '🇵🇬',
    countryCallingCode: '675',
  },
  {
    countryNameEn: 'Paraguay',
    countryNameLocal: 'Paraguay',
    countryCode: 'PY',
    region: 'South/Latin America',
    flag: '🇵🇾',
    countryCallingCode: '595',
  },
  {
    countryNameEn: 'Peru',
    countryNameLocal: 'Perú',
    countryCode: 'PE',
    region: 'South/Latin America',
    flag: '🇵🇪',
    countryCallingCode: '51',
  },
  {
    countryNameEn: 'Philippines',
    countryNameLocal: 'Philippines',
    countryCode: 'PH',
    region: 'Asia & Pacific',
    flag: '🇵🇭',
    countryCallingCode: '63',
  },
  {
    countryNameEn: 'Poland',
    countryNameLocal: 'Polska',
    countryCode: 'PL',
    region: 'Europe',
    flag: '🇵🇱',
    countryCallingCode: '48',
  },
  {
    countryNameEn: 'Portugal',
    countryNameLocal: 'Portugal',
    countryCode: 'PT',
    region: 'Europe',
    flag: '🇵🇹',
    countryCallingCode: '351',
  },
  {
    countryNameEn: 'Puerto Rico',
    countryNameLocal: 'Puerto Rico',
    countryCode: 'PR',
    region: 'South/Latin America',
    flag: '🇵🇷',
    countryCallingCode: '1',
  },
  {
    countryNameEn: 'Qatar',
    countryNameLocal: 'قطر',
    countryCode: 'QA',
    region: 'Arab States',
    flag: '🇶🇦',
    countryCallingCode: '974',
  },
  {
    countryNameEn: 'Republic of Moldova',
    countryNameLocal: 'Moldova, Молдавия',
    countryCode: 'MD',
    region: 'Europe',
    flag: '🇲🇩',
    countryCallingCode: '373',
  },
  {
    countryNameEn: 'Republic of the Congo',
    countryNameLocal: 'République du Congo',
    countryCode: 'CG',
    region: 'Africa',
    flag: '🇨🇬',
    countryCallingCode: '242',
  },
  {
    countryNameEn: 'Republic of the Marshall Islands',
    countryNameLocal: 'Aolepān Aorōkin Ṃajeḷ',
    countryCode: 'MH',
    region: 'Pacific Ocean',
    flag: '🇲🇭',
    countryCallingCode: '692',
  },
  {
    countryNameEn: 'Romania',
    countryNameLocal: 'România',
    countryCode: 'RO',
    region: 'Europe',
    flag: '🇷🇴',
    countryCallingCode: '40',
  },
  {
    countryNameEn: 'Russia',
    countryNameLocal: 'Россия',
    countryCode: 'RU',
    region: 'Europe',
    flag: '🇷🇺',
    countryCallingCode: '7',
  },
  {
    countryNameEn: 'Rwanda',
    countryNameLocal: 'Rwanda',
    countryCode: 'RW',
    region: 'Africa',
    flag: '🇷🇼',
    countryCallingCode: '250',
  },
  {
    countryNameEn: 'Réunion',
    countryNameLocal: 'La Réunion',
    countryCode: 'RE',
    region: 'Asia & Pacific',
    flag: '🇷🇪',
    countryCallingCode: '262',
  },
  {
    countryNameEn: 'Saint Barthélemy',
    countryNameLocal: 'Saint-Barthélemy',
    countryCode: 'BL',
    region: 'South/Latin America',
    flag: '🇧🇱',
    countryCallingCode: '590',
  },
  {
    countryNameEn: 'Saint Helena, Ascension and Tristan da Cunha',
    countryNameLocal: 'Saint Helena',
    countryCode: 'SH',
    region: 'Africa',
    flag: '🇸🇭',
    countryCallingCode: '290',
  },
  {
    countryNameEn: 'Saint Kitts and Nevis',
    countryNameLocal: 'Saint Kitts and Nevis',
    countryCode: 'KN',
    region: 'South/Latin America',
    flag: '🇰🇳',
    countryCallingCode: '1869',
  },
  {
    countryNameEn: 'Saint Lucia',
    countryNameLocal: 'Saint Lucia',
    countryCode: 'LC',
    region: 'South/Latin America',
    flag: '🇱🇨',
    countryCallingCode: '1758',
  },
  {
    countryNameEn: 'Saint Martin (French part)',
    countryNameLocal: 'Saint-Martin',
    countryCode: 'MF',
    region: 'South/Latin America',
    flag: '🇲🇫',
    countryCallingCode: '590',
  },
  {
    countryNameEn: 'Saint Pierre and Miquelon',
    countryNameLocal: 'Saint-Pierre-et-Miquelon',
    countryCode: 'PM',
    region: 'North America',
    flag: '🇵🇲',
    countryCallingCode: '508',
  },
  {
    countryNameEn: 'Saint Vincent and the Grenadines',
    countryNameLocal: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    region: 'South/Latin America',
    flag: '🇻🇨',
    countryCallingCode: '1784',
  },
  {
    countryNameEn: 'Samoa',
    countryNameLocal: 'Samoa',
    countryCode: 'WS',
    region: 'Asia & Pacific',
    flag: '🇼🇸',
    countryCallingCode: '685',
  },
  {
    countryNameEn: 'San Marino',
    countryNameLocal: 'San Marino',
    countryCode: 'SM',
    region: 'Europe',
    flag: '🇸🇲',
    countryCallingCode: '378',
  },
  {
    countryNameEn: 'Sao Tome and Principe',
    countryNameLocal: 'São Tomé e Príncipe',
    countryCode: 'ST',
    region: 'Africa',
    flag: '🇸🇹',
    countryCallingCode: '239',
  },
  {
    countryNameEn: 'Saudi Arabia',
    countryNameLocal: 'السعودية',
    countryCode: 'SA',
    region: 'Arab States',
    flag: '🇸🇦',
    countryCallingCode: '966',
  },
  {
    countryNameEn: 'Senegal',
    countryNameLocal: 'Sénégal',
    countryCode: 'SN',
    region: 'Africa',
    flag: '🇸🇳',
    countryCallingCode: '221',
  },
  {
    countryNameEn: 'Serbia',
    countryNameLocal: 'Србија',
    countryCode: 'RS',
    region: 'Europe',
    flag: '🇷🇸',
    countryCallingCode: '381',
  },
  {
    countryNameEn: 'Seychelles',
    countryNameLocal: 'Seychelles',
    countryCode: 'SC',
    region: 'Africa',
    flag: '🇸🇨',
    countryCallingCode: '248',
  },
  {
    countryNameEn: 'Sierra Leone',
    countryNameLocal: 'Sierra Leone',
    countryCode: 'SL',
    region: 'Africa',
    flag: '🇸🇱',
    countryCallingCode: '232',
  },
  {
    countryNameEn: 'Singapore',
    countryNameLocal: 'Singapore',
    countryCode: 'SG',
    region: 'Asia & Pacific',
    flag: '🇸🇬',
    countryCallingCode: '65',
  },
  {
    countryNameEn: 'Sint Maarten (Dutch part)',
    countryNameLocal: 'Sint Maarten',
    countryCode: 'SX',
    region: 'Unknown',
    flag: '🇸🇽',
    countryCallingCode: '1721',
  },
  {
    countryNameEn: 'Slovakia',
    countryNameLocal: 'Slovensko',
    countryCode: 'SK',
    region: 'Europe',
    flag: '🇸🇰',
    countryCallingCode: '421',
  },
  {
    countryNameEn: 'Slovenia',
    countryNameLocal: 'Slovenija',
    countryCode: 'SI',
    region: 'Europe',
    flag: '🇸🇮',
    countryCallingCode: '386',
  },
  {
    countryNameEn: 'Solomon Islands',
    countryNameLocal: 'Solomon Islands',
    countryCode: 'SB',
    region: 'Asia & Pacific',
    flag: '🇸🇧',
    countryCallingCode: '677',
  },
  {
    countryNameEn: 'Somalia',
    countryNameLocal: 'Somalia, الصومال',
    countryCode: 'SO',
    region: 'Arab States',
    flag: '🇸🇴',
    countryCallingCode: '252',
  },
  {
    countryNameEn: 'South Africa',
    countryNameLocal: 'South Africa',
    countryCode: 'ZA',
    region: 'Africa',
    flag: '🇿🇦',
    countryCallingCode: '27',
  },
  {
    countryNameEn: 'South Korea',
    countryNameLocal: '대한민국',
    countryCode: 'KR',
    region: 'Asia',
    flag: '🇰🇷',
    countryCallingCode: '82',
  },
  {
    countryNameEn: 'South Sudan',
    countryNameLocal: 'South Sudan',
    countryCode: 'SS',
    region: 'Africa',
    flag: '🇸🇸',
    countryCallingCode: '211',
  },
  {
    countryNameEn: 'Sri Lanka',
    countryNameLocal: 'ශ්‍රී ලංකා, இலங்கை',
    countryCode: 'LK',
    region: 'Asia & Pacific',
    flag: '🇱🇰',
    countryCallingCode: '94',
  },
  {
    countryNameEn: 'Sudan',
    countryNameLocal: 'السودان',
    countryCode: 'SD',
    region: 'Arab States',
    flag: '🇸🇩',
    countryCallingCode: '249',
  },
  {
    countryNameEn: 'Suriname',
    countryNameLocal: 'Suriname',
    countryCode: 'SR',
    region: 'South/Latin America',
    flag: '🇸🇷',
    countryCallingCode: '597',
  },
  {
    countryNameEn: 'Svalbard and Jan Mayen',
    countryNameLocal: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
    region: 'Europe',
    flag: '🇸🇯',
    countryCallingCode: '4779',
  },
  {
    countryNameEn: 'Sweden',
    countryNameLocal: 'Sverige',
    countryCode: 'SE',
    region: 'Europe',
    flag: '🇸🇪',
    countryCallingCode: '46',
  },
  {
    countryNameEn: 'Switzerland',
    countryNameLocal: 'Schweiz, Suisse, Svizzera, Svizra',
    countryCode: 'CH',
    region: 'Europe',
    flag: '🇨🇭',
    countryCallingCode: '41',
  },
  {
    countryNameEn: 'Syrian Arab Republic',
    countryNameLocal: 'سوريا, Sūriyya',
    countryCode: 'SY',
    region: 'Asia & Pacific',
    flag: '🇸🇾',
    countryCallingCode: '963',
  },
  {
    countryNameEn: 'Taiwan, Province of China',
    countryNameLocal: 'Taiwan',
    countryCode: 'TW',
    region: 'Asia & Pacific',
    flag: '🇹🇼',
    countryCallingCode: '886',
  },
  {
    countryNameEn: 'Tajikistan',
    countryNameLocal: ',',
    countryCode: 'TJ',
    region: 'Asia & Pacific',
    flag: '🇹🇯',
    countryCallingCode: '992',
  },
  {
    countryNameEn: 'Thailand',
    countryNameLocal: 'ประเทศไทย',
    countryCode: 'TH',
    region: 'Asia & Pacific',
    flag: '🇹🇭',
    countryCallingCode: '66',
  },
  {
    countryNameEn: 'Timor-Leste',
    countryNameLocal: "Timor-Leste, Timor Lorosa'e",
    countryCode: 'TL',
    region: 'Asia & Pacific',
    flag: '🇹🇱',
    countryCallingCode: '670',
  },
  {
    countryNameEn: 'Togo',
    countryNameLocal: 'Togo',
    countryCode: 'TG',
    region: 'Africa',
    flag: '🇹🇬',
    countryCallingCode: '228',
  },
  {
    countryNameEn: 'Tokelau',
    countryNameLocal: 'Tokelau',
    countryCode: 'TK',
    region: 'Asia & Pacific',
    flag: '🇹🇰',
    countryCallingCode: '690',
  },
  {
    countryNameEn: 'Tonga',
    countryNameLocal: 'Tonga',
    countryCode: 'TO',
    region: 'Asia & Pacific',
    flag: '🇹🇴',
    countryCallingCode: '676',
  },
  {
    countryNameEn: 'Trinidad and Tobago',
    countryNameLocal: 'Trinidad and Tobago',
    countryCode: 'TT',
    region: 'South/Latin America',
    flag: '🇹🇹',
    countryCallingCode: '868',
  },
  {
    countryNameEn: 'Tunisia',
    countryNameLocal: 'تونس, Tunisie',
    countryCode: 'TN',
    region: 'Arab States',
    flag: '🇹🇳',
    countryCallingCode: '216',
  },
  {
    countryNameEn: 'Turkey',
    countryNameLocal: 'Türkiye',
    countryCode: 'TR',
    region: 'Europe',
    flag: '🇹🇷',
    countryCallingCode: '90',
  },
  {
    countryNameEn: 'Turkmenistan',
    countryNameLocal: 'Türkmenistan',
    countryCode: 'TM',
    region: 'Asia & Pacific',
    flag: '🇹🇲',
    countryCallingCode: '993',
  },
  {
    countryNameEn: 'Turks and Caicos Islands',
    countryNameLocal: 'Turks and Caicos Islands',
    countryCode: 'TC',
    region: 'Atlantic Ocean',
    flag: '🇹🇨',
    countryCallingCode: '1 649',
  },
  {
    countryNameEn: 'Tuvalu',
    countryNameLocal: 'Tuvalu',
    countryCode: 'TV',
    region: 'Asia & Pacific',
    flag: '🇹🇻',
    countryCallingCode: '688',
  },
  {
    countryNameEn: 'Uganda',
    countryNameLocal: 'Uganda',
    countryCode: 'UG',
    region: 'Africa',
    flag: '🇺🇬',
    countryCallingCode: '256',
  },
  {
    countryNameEn: 'Ukraine',
    countryNameLocal: 'Україна',
    countryCode: 'UA',
    region: 'Europe',
    flag: '🇺🇦',
    countryCallingCode: '380',
  },
  {
    countryNameEn: 'United Arab Emirates',
    countryNameLocal: 'دولة الإمارات العربيّة المتّحدة',
    countryCode: 'AE',
    region: 'Arab States',
    flag: '🇦🇪',
    countryCallingCode: '971',
  },
  {
    countryNameEn: 'United Republic of Tanzania',
    countryNameLocal: 'Tanzania',
    countryCode: 'TZ',
    region: 'Africa',
    flag: '🇹🇿',
    countryCallingCode: '255',
  },
  {
    countryNameEn: 'Uruguay',
    countryNameLocal: 'Uruguay',
    countryCode: 'UY',
    region: 'South/Latin America',
    flag: '🇺🇾',
    countryCallingCode: '598',
  },
  {
    countryNameEn: 'Uzbekistan',
    countryNameLocal: '',
    countryCode: 'UZ',
    region: 'Asia & Pacific',
    flag: '🇺🇿',
    countryCallingCode: '998',
  },
  {
    countryNameEn: 'Vanuatu',
    countryNameLocal: 'Vanuatu',
    countryCode: 'VU',
    region: 'Asia & Pacific',
    flag: '🇻🇺',
    countryCallingCode: '678',
  },
  {
    countryNameEn: 'Venezuela (Bolivarian Republic of)',
    countryNameLocal: 'Venezuela',
    countryCode: 'VE',
    region: 'South/Latin America',
    flag: '🇻🇪',
    countryCallingCode: '58',
  },
  {
    countryNameEn: 'Vietnam',
    countryNameLocal: 'Việt Nam',
    countryCode: 'VN',
    region: 'Asia & Pacific',
    flag: '🇻🇳',
    countryCallingCode: '84',
  },
  {
    countryNameEn: 'Virgin Islands (British)',
    countryNameLocal: 'British Virgin Islands',
    countryCode: 'VG',
    region: 'South/Latin America',
    flag: '🇻🇬',
    countryCallingCode: '1284',
  },
  {
    countryNameEn: 'Virgin Islands (U.S.)',
    countryNameLocal: 'United States Virgin Islands',
    countryCode: 'VI',
    region: 'South/Latin America',
    flag: '🇻🇮',
    countryCallingCode: '1340',
  },
  {
    countryNameEn: 'Wallis and Futuna',
    countryNameLocal: 'Wallis-et-Futuna',
    countryCode: 'WF',
    region: 'Asia & Pacific',
    flag: '🇼🇫',
    countryCallingCode: '681',
  },
  {
    countryNameEn: 'Western Sahara',
    countryNameLocal: 'Sahara Occidental',
    countryCode: 'EH',
    region: 'Africa',
    flag: '🇪🇭',
    countryCallingCode: '212',
  },
  {
    countryNameEn: 'Yemen',
    countryNameLocal: 'اليَمَن',
    countryCode: 'YE',
    region: 'Arab States',
    flag: '🇾🇪',
    countryCallingCode: '967',
  },
  {
    countryNameEn: 'Zambia',
    countryNameLocal: 'Zambia',
    countryCode: 'ZM',
    region: 'Africa',
    flag: '🇿🇲',
    countryCallingCode: '260',
  },
  {
    countryNameEn: 'Zimbabwe',
    countryNameLocal: 'Zimbabwe',
    countryCode: 'ZW',
    region: 'Africa',
    flag: '🇿🇼',
    countryCallingCode: '263',
  },
  {
    countryNameEn: 'Åland Islands',
    countryNameLocal: 'Åland',
    countryCode: 'AX',
    region: 'Europe',
    flag: '🇦🇽',
    countryCallingCode: '358',
  },
];
