import classNames from 'clsx';
import * as React from 'react';

export const Card = React.memo<{
  emoji: string;
  title: string;
  subtitle?: string;
  loading?: boolean;
  emojiClassname?: string;
  children: React.ReactNode;
}>(({ emoji, emojiClassname, title, subtitle, loading, children }) => {
  return (
    <div className="flex flex-col items-center w-screen p-16 bg-white shadow-md rounded-xl sm:max-w-md sm:min-w-min">
      <div className="relative">
        <div
          className={classNames('flex items-center justify-center w-24 h-24 text-5xl rounded-full', emojiClassname)}
          style={{ background: '#EFF4F8' }}
        >
          {emoji}
        </div>

        {loading && <div className="absolute inset-0 z-10 border-t-4 border-yellow-300 rounded-t-full animate-spin" />}
      </div>

      <div className="mt-6 text-xl font-semibold text-center">{title}</div>
      {subtitle && <div className="w-full mt-2 text-center text-gray-400 sm:w-2/3 text-muted">{subtitle}</div>}

      {children}
    </div>
  );
});
